import { optimizedScroll } from '../util/optimizescroll'

export default class Header {
  constructor($el) {
    this.$el = $($el)
    this.offset = 50
    this.activeClass = 'is-scrolling'

    optimizedScroll(scrollPosition => {
      if (scrollPosition > this.offset) {
        this.$el.addClass(this.activeClass)
      } else {
        this.$el.removeClass(this.activeClass)
      }
    })
  }
}
