import Flickity from 'flickity'
import 'flickity-imagesloaded'

export default class Carousel {
  constructor(el) {
    this.el = el

    new Flickity(this.el, {
      wrapAround: true,
      pageDots: false,
      cellSelector: '.js-carousel-slide',
      autoPlay: $(this.el).data('autoplay'),
      pauseAutoPlayOnHover: false,
      imagesLoaded: true,
    })
  }
}
