import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Mq from '../util/mq'

export default class MobileNav {
  constructor($el) {
    this.el = $el
    this.activeClass = 'is-mobile-nav-open'
    this.$btnClose = $(this.el).find('.c-mobile-nav__close')
    this.$navList = $(this.el).find('.c-mobile-nav__list')

    const $trigger = $('.js-mobile-nav-trigger')
    $trigger.on('click', e => {
      e.preventDefault()

      if ($('html').hasClass(this.activeClass)) {
        this.closeNav()
      } else {
        this.openNav()
      }
    })

    this.$btnClose.on('click', () => {
      this.closeNav()
    })

    Mq.atLeast('--site-nav-breakpoint', e => {
      if (e.matches) {
        this.closeNav()
      }
    })

    $(document).on('click', e => {
      const $target = $(e.target)
      if (
        !$target.closest(this.el).length &&
        !$target.closest(this.$btnClose).length &&
        !$target.closest($trigger).length
      ) {
        this.closeNav()
      }
    })

    this.$navList.find('li').each((index, element) => {
      if ($(element).find('ul').length) {
        $(element).addClass('has-dropdown')
      }
    })
  }

  openNav() {
    $('html').addClass(this.activeClass)
    disableBodyScroll(this.el)
  }

  closeNav() {
    $('html').removeClass(this.activeClass)
    enableBodyScroll(this.el)
  }
}
