export default class SiteNav {
  constructor($el) {
    this.$el = $($el)
    this.$navList = this.$el.find('.c-site-nav__list')

    this.$navList.find('li ul').each((index, element) => {
      $(element)
        .parent()
        .addClass('has-dropdown')
    })

    $('.has-dropdown').on('mouseenter', e => {
      const dropdown = $(e.currentTarget).find('ul')

      if (this.edgeDetect(dropdown)) {
        dropdown.addClass('is-edge')
      }
    })
  }

  edgeDetect(el) {
    return $(el).offset().left + $(el).outerWidth() - $(window).scrollLeft() > $(window).width()
  }
}
