// import external dependencies
import 'jquery'

// import local dependencies
import './modules'

import svg4everybody from 'svg4everybody'
svg4everybody()

import 'lity'

import ReadMore from './util/readmore'
ReadMore.init()
