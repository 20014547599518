export function optimizedScroll(fn) {
  let last_known_scroll_position = 0
  let ticking = false

  window.addEventListener('scroll', function() {
    last_known_scroll_position = window.scrollY

    if (!ticking) {
      window.requestAnimationFrame(function() {
        fn(last_known_scroll_position)
        ticking = false
      })

      ticking = true
    }
  })
}
