class Mq {
  static stripUnit(el) {
    return parseInt(String(el).trim(), 10)
  }

  static get(prop, el = document.documentElement) {
    const styles = getComputedStyle(el)
    return this.stripUnit(styles.getPropertyValue(prop))
  }

  static minWidth(size) {
    let bp = size

    if (this.get(size)) {
      bp = this.get(size)
    }

    return window.matchMedia(`(min-width: ${bp}px)`)
  }

  static maxWidth(size) {
    let bp = size

    if (this.get(size)) {
      bp = this.get(size)
    }
    return window.matchMedia(`(max-width: ${bp - 0.02}px)`)
  }

  static atLeast(bp, fn) {
    const breakpoint = this.minWidth(bp)

    fn(breakpoint)
    breakpoint.addListener(fn)
  }

  static atMost(bp, fn, self = true) {
    const breakpoint = this.maxWidth(bp)

    if (self) fn(breakpoint)
    breakpoint.addListener(fn)
  }

}

export default Mq